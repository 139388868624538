import React, { useState } from 'react';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';
import { compose } from 'redux';
import { useSwipeable } from 'react-swipeable';

import { propTypes } from '../../../util/types';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { withViewport } from '../../../util/uiHelpers';
import {
  AspectRatioWrapper,
  Button,
  IconClose,
  IconArrowHead,
  ResponsiveImage,
} from '../../../components';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ListingImageGallery.module.css';
import { useRef } from 'react';

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  disableThumbnailScroll: true,
};
const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;
const MODAL_BREAKPOINT = 1023;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  // We keep the fractions separated as these are given to AspectRatioWrapper
  // which expects separate width and height
  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
    ? { aspectWidth: 4, aspectHeight: 3 }
    : hasDimensions
    ? { aspectWidth: w, aspectHeight: h }
    : { aspectWidth: 1, aspectHeight: 1 };
};

/**
 * The ListingImageGallery component.
 *
 * @component
 * @param {Object} props
 * @param {string} [props.className] - Custom class that extends the default class for the root element
 * @param {string} [props.rootClassName] - Custom class that overrides the default class for the root element
 * @param {Array<propTypes.image>} props.images - The images
 * @param {Array<string>} props.imageVariants - The image variants
 * @param {Array<string>} props.thumbnailVariants - The thumbnail variants
 * @param {Function} props.onManageDisableScrolling
 * @param {Object} props.viewport
 * @param {number} props.viewport.width
 * @param {number} props.viewport.height
 * @returns {JSX.Element} listing image gallery component
 */
const ListingImageGallery = props => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobileFullscreen, setIsMobileFullscreen] = useState(false);
  const galleryRef = useRef(null);
  const intl = useIntl();
  const {
    rootClassName,
    className,
    images,
    imageVariants,
    thumbnailVariants,
    onManageDisableScrolling,
    viewport,
  } = props;
  const isMobileLayout = viewport.width <= MODAL_BREAKPOINT;
  const thumbVariants = thumbnailVariants || imageVariants;
  // imageVariants are scaled variants.
  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(images?.[0], imageVariants[0]);
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });
  const currentMobileImageFullscreen = items[currentIndex];
  const imageSizesMaybe = isFullscreen
    ? {}
    : { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };

  const toggleMobileFullscreen = () => {
    const componentId = 'listingImageFullscreen';

    if (isMobileFullscreen) {
      setCurrentIndex(null);
      setIsMobileFullscreen(false);
      onManageDisableScrolling(componentId, false);
    } else {
      setCurrentIndex(galleryRef.current.getCurrentIndex());
      setIsMobileFullscreen(true);
      onManageDisableScrolling(componentId, true);
    }
  };

  const renderItem = item => {
    return (
      <AspectRatioWrapper
        width={aspectWidth || 1}
        height={aspectHeight || 1}
        className={isFullscreen ? css.itemWrapperFullscreen : css.itemWrapper}
        {...(isMobileLayout ? { onClick: toggleMobileFullscreen } : {})}
      >
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
            {...imageSizesMaybe}
          />
        </div>
      </AspectRatioWrapper>
    );
  };
  const renderThumbInner = item => {
    return (
      <div>
        <ResponsiveImage
          rootClassName={css.thumb}
          image={item.image}
          alt={item.thumbAlt}
          variants={thumbVariants}
          sizes="88px"
        />
      </div>
    );
  };

  const onScreenChange = isFull => {
    setIsFullscreen(isFull);
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="right" size="big" />
        </div>
      </button>
    );
  };
  const renderFullscreenButton = (onClick, isFullscreen) => {
    return isFullscreen ? (
      <Button
        onClick={onClick}
        rootClassName={css.close}
        title={intl.formatMessage({ id: 'ListingImageGallery.closeModalTitle' })}
      >
        <span className={css.closeText}>
          <FormattedMessage id="ListingImageGallery.closeModal" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    ) : (
      <button className={css.openFullscreen} onClick={onClick}>
        <FormattedMessage
          id="ListingImageGallery.viewImagesButton"
          values={{ count: images.length }}
        />
      </button>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const swipeHandlers =
    typeof window !== 'undefined'
      ? useSwipeable({
          onSwipedLeft: () => {
            if (currentIndex < items.length - 1) {
              const nextIndex = currentIndex + 1;
              setCurrentIndex(nextIndex);
              galleryRef.current.slideToIndex(nextIndex);
            }
          },
          onSwipedRight: () => {
            if (currentIndex > 0) {
              const previousIndex = currentIndex - 1;
              setCurrentIndex(previousIndex);
              galleryRef.current.slideToIndex(previousIndex);
            }
          },
          preventScrollOnSwipe: true,
        })
      : null;

  const mobileImageFullscreen =
    isMobileLayout && isMobileFullscreen ? (
      <div className={css.mobileFullscreenContainer} {...swipeHandlers}>
        <Button
          onClick={toggleMobileFullscreen}
          rootClassName={css.close}
          title={intl.formatMessage({ id: 'ListingImageGallery.closeModalTitle' })}
        >
          <span className={css.closeText}>
            <FormattedMessage id="ListingImageGallery.closeModal" />
          </span>
          <IconClose rootClassName={css.closeIcon} />
        </Button>
        <ResponsiveImage
          rootClassName={css.mobileFullscreenImg}
          image={currentMobileImageFullscreen.image}
          alt={currentMobileImageFullscreen.alt}
          variants={imageVariants}
          {...imageSizesMaybe}
        />
      </div>
    ) : null;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <ReactImageGallery
        ref={galleryRef}
        additionalClass={classes}
        items={items}
        renderItem={renderItem}
        renderThumbInner={renderThumbInner}
        onScreenChange={onScreenChange}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        renderFullscreenButton={renderFullscreenButton}
        {...IMAGE_GALLERY_OPTIONS}
      />
      {mobileImageFullscreen}
    </>
  );
};

export default compose(withViewport)(ListingImageGallery);
